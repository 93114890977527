@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

html {
  scroll-behavior: smooth;
}

.section {
  @apply px-5 max-w-[1560px] mx-auto 2xl:max-w-[1240px];
}

.containerAlt {
  margin-right: auto;
  margin-left: auto;
  max-width: 1650px;
  width: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev {
  display: none !important;
}

.swiper-button-next {
  display: none !important;
}

.footer-links {
  @apply cursor-pointer hover:text-theme-bg dark:hover:text-white;
}


@media (max-width: 425px) {
  .mySwiper {
    width: 90vw;
  }
}

@media (max-width: 762px) {
  .mySwiper {}
}

.truncate-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 762px) {
  .remove-line-clamp {
    -webkit-line-clamp: inherit;
  }
}

@media (max-width: 900px) {
  .option > div:last-child {
    all: unset !important;
  }
}

.myfocuse {
  width: calc(100% + 16px);
}

.word-break-keepAll {
  word-break: keep-all;
}

/* 
.myfocuse svg {
  width: 100%;
}

.myfocuse svg rect {
  width: calc(100% - 3px);
} */

.centeredSwiper .swiper-wrapper {
  display: flex;
  justify-content: center;
}

#category-select .selbox {
  width: 100%;
}

#category-select .options {
  top: 0px !important;
}

#category-select {
  height: 38px;
}

#category-select .apparent {
  height: 100% !important;
  padding: 8px 12px !important;
}

.selbox>.arrow {
  right: 10px !important;
  top: 8px !important;
}

.dark #category-select .selbox {
  border: 0px solid #dcdee3;
  background-color: #252423;
}

.dark #category-select .search {
  background-color: #252423;
}

.dark #category-select div .option .text:hover {
  background-color: #555;
}

.pagination-item {
  position: relative;
}

.pagination-item::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-image: url('/public/images/shapes/hover-container.svg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  display: none;
}

.pagination-item.hovered::before {
  display: block;
}

.privacy {
  position: relative;
}

/* .privacy h1 {
  font-size: 4em;
  font-weight: bold;
} */

.privacy h2 {
  font-size: 4em;
  font-weight: bold;
}

.privacy h3 {
  font-size: 3em;
  font-weight: bold;
}

.privacy h4 {
  font-size: 2.5em;
  font-weight: bold;
}

.privacy h5 {
  font-size: 1.5em;
  font-weight: bold;
}

.privacy p {
  font-size: 1.5em;
  padding-bottom: 1rem;
}

.privacy b {
  font-size: 1.5em;
  padding-bottom: 1rem;
  font-weight: bold;
}

.privacy ul {
  padding-left: 1.5em;
  font-size: 1.5em;
  list-style-type: circle;
}

.privacy ol {
  padding-left: 1.5em;
  font-size: 1.5em;
  list-style-type: upper-alpha;
}

.privacy ol ul {
  padding-left: 1em;
  font-size: 1em;
  list-style-type: circle;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.solar-container {
  display: block;
  padding: 4em;
  max-width: 1600px;
  margin: auto;
}

.solar-container h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
.solar-container h2 {
  font-size: 2.5em;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
}
.solar-container h3 {
  font-size: 2em;
  margin-bottom: 30px;
  font-weight: bold;
}
.solar-container h4 {
  font-size: 2.2em;
  margin-top: 30px;
  font-weight: bold;
}
.solar-container p {
  margin: 10px 0;
  font-size: 1.5em;
}

.solar-container ul {
  margin: 10px 0 10px 20px;
  list-style-type: circle;
  font-size: 1.5em;
}

.solar-container ol {
  margin: 10px 0 10px 20px;
  list-style-type: lower-roman;
  font-size: 1.5em;
}

.solar-container li {
  margin: 5px 0;
}

.solar-container a {
  color: aqua;
}

.solar-container span {
  font-weight: bold;
  font-size: 1.2em;
}

#category-select .selected {
  background-color: #0d0d0d66 !important;
}

.option > div:last-child {
  position: fixed;
  z-index: 300;
  left: 100%;
  background-color: #252423;
  min-width: 200px;
  cursor: context-menu;
}

.arrow img {
  filter: invert(100%);
}

#category-select .option .text {
  border-color: #FFFFFF1A !important;
}

.scrollable-section {
  position: relative;
  overflow: hidden;
  height: 100vh; /* Full viewport height */
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%; /* Twice the height of the viewport for scrolling effect */
  background: #FFFFFF;
  transform: translateY(-100%);
  transition: transform 1s ease-in-out;
}

.content.animate {
  transform: translateY(0);
}

.footer-gradient {
  background: linear-gradient(to top, black, transparent);
}

.product-card-bg-1 {
  background: radial-gradient(52.03% 36.11% at 3.11% 79.84%, rgba(255, 102, 0, 0.1) 0%, rgba(255, 102, 0, 0) 100%),
    radial-gradient(240.16% 216.94% at 14.35% 100%, rgba(255, 102, 0, 0.04) 0%, rgba(255, 102, 0, 0) 100%), #222;
}

.product-card-bg-2 {
  background: radial-gradient(73.37% 49.03% at 6.01% 4%, rgba(255, 102, 0, 0.15) 0%, rgba(255, 102, 0, 0) 100%),
    radial-gradient(60.97% 51.03% at 92.11% 95.89%, rgba(255, 102, 0, 0.04) 0%, rgba(255, 102, 0, 0) 100%), #222;
}

.product-card-bg-3 {
  background: radial-gradient(83.29% 58.09% at 15.74% 96.91%, rgba(82, 225, 242, 0.2) 0%, rgba(82, 225, 242, 0) 100%),
    radial-gradient(74.08% 67.54% at 95.56% 3.09%, rgba(239, 68, 68, 0.1) 0%, rgba(239, 68, 68, 0) 100%), #222;
}

.product-card-bg-4 {
  background: radial-gradient(96.69% 62.02% at 2.37% 4.33%, rgba(32, 167, 92, 0.2) 0%, rgba(32, 167, 92, 0) 100%),
    radial-gradient(39.57% 33.3% at 49.97% 97.4%, rgba(32, 167, 92, 0.1) 0%, rgba(32, 167, 92, 0) 100%), #222;
}

.product-card-bg-5 {
  background: radial-gradient(96.69% 62.02% at 2.37% 4.33%, rgba(249, 163, 35, 0.2) 0%, rgba(249, 163, 35, 0) 100%),
    radial-gradient(39.57% 33.3% at 49.97% 97.4%, rgba(249, 163, 35, 0.1) 0%, rgba(249, 163, 35, 0) 100%), #222;
}

.silver-sponsor-center {
  left: calc(50vw - 86px);
}
