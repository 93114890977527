.page-template .gh-article-header {
  margin-block: 72px 32px;
}
.page-template .gh-content:only-child > :first-child:not(.kg-width-full) {
  margin-top: 64px;
}
.page-template .gh-content > :last-child:not(.kg-width-full) {
  margin-bottom: 6vw;
}
.page-template .gh-footer {
  margin-top: 0;
}
.gh-content {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.gh-content > * + * {
  margin-bottom: 0;
  margin-top: calc(28px * var(--content-spacing-factor, 1));
}
.gh-content
  > .kg-width-full
  + .kg-width-full:not(.kg-width-full.kg-card-hascaption + .kg-width-full) {
  margin-top: 0;
}
.gh-content > [id]:not(:first-child) {
  margin-top: calc(56px * var(--content-spacing-factor, 1));
}
.post-template .gh-content.drop-cap > p:first-of-type:first-letter {
  float: left;
  font-size: 3.1em;
  font-weight: 700;
  line-height: 1;
  margin: 0 0.2em 0 -1px;
}
.has-serif-body.post-template
  .gh-content.drop-cap
  > p:first-of-type:first-letter {
  font-size: 3.2em;
}
.gh-content > [id] + p {
  margin-top: calc(12px * var(--content-spacing-factor, 1));
}
.gh-content > :is(hr, blockquote, iframe) {
  margin-top: calc(48px * var(--content-spacing-factor, 1)) !important;
  position: relative;
}
.gh-content > :is(hr, blockquote, iframe) + * {
  margin-top: calc(48px * var(--content-spacing-factor, 1)) !important;
}
.gh-content h1 {
  font-size: calc(2.2em * var(--factor, 1));
  letter-spacing: -0.02em;
}
.gh-content h3 {
  font-size: calc(1.3em * var(--factor, 1));
  letter-spacing: -0.017em;
}
.gh-content a {
  color: var(--ghost-accent-color);
  text-decoration: underline;
}
.gh-content .kg-callout-card .kg-callout-text,
.gh-content .kg-toggle-card .kg-toggle-content > :is(ul, ol, p) {
  font-size: 0.95em;
}
.has-serif-body .gh-content .kg-callout-text,
.has-serif-body .gh-content .kg-toggle-content > ol,
.has-serif-body .gh-content .kg-toggle-content > p,
.has-serif-body .gh-content .kg-toggle-content > ul,
.has-serif-body .gh-content > blockquote,
.has-serif-body .gh-content > dl,
.has-serif-body .gh-content > ol,
.has-serif-body .gh-content > p,
.has-serif-body .gh-content > ul {
  font-family: var(--font-serif-alt);
}
.gh-content :is(ul, ol) {
  padding-left: 28px;
}
.gh-content :is(li + li, li :is(ul, ol)) {
  margin-top: 8px;
}
.gh-content ol ol li {
  list-style-type: lower-alpha;
}
.gh-content ol ol ol li {
  list-style-type: lower-roman;
}
.gh-content hr {
  background-color: var(--color-border);
  border: 0;
  height: 1px;
  width: 100%;
}
.gh-content .gh-table {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}
.gh-content .gh-table table {
  border-collapse: collapse;
  border-spacing: 0;
  font-family: var(--font-sans);
  font-size: 1.5rem;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
}
.gh-content .gh-table table th {
  color: var(--color-darkgrey);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: uppercase;
}
.gh-content .gh-table table :is(th, td),
.gh-content .gh-table table td {
  border-bottom: 1px solid var(--color-border);
  padding: 6px 12px;
}
.gh-content .gh-table table :is(th, td):first-child {
  padding-left: 0;
}
.gh-content .gh-table table :is(th, td):last-child {
  padding-right: 0;
}
.gh-content pre {
  border-radius: 6px;
  font-size: 1.5rem;
  line-height: 1.5em;
  overflow: auto;
  padding: 16px;
}
.gh-content :not(pre) > code,
.gh-content pre {
  background: var(--color-lighter-gray);
  font-family: var(--font-mono);
}
.gh-content :not(pre) > code {
  border-radius: 0.25em;
  font-size: 0.95em;
  font-weight: 400;
  line-height: 1em;
  padding: 0.15em 0.4em;
  vertical-align: baseline;
}
.gh-content :not(.kg-card):not(table):not([id]) + :is(.kg-card, table) {
  margin-top: calc(48px * var(--content-spacing-factor, 1));
}
.gh-content :is(.kg-card, table) + :not(.kg-card):not(table):not([id]) {
  margin-top: calc(48px * var(--content-spacing-factor, 1));
}
.gh-content .kg-card.kg-width-full + :not(.kg-card):not([id]),
.gh-content :not(.kg-card):not([id]) + .kg-card.kg-width-full {
  margin-top: calc(68px * var(--content-spacing-factor, 1));
}
.gh-content ul {
  list-style: disc;
} 
.gh-content ol {
  list-style: decimal;
}
.gh-content ul strong, .gh-content ol strong {
  color: #ED5C15;
}
.gh-content h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 110%;
}
.gh-content > p {
  word-wrap: break-word;
}